.seat-info-container {
      padding: 20px;
      padding-bottom: 0;
}

.seat-map {
      display: flex;
      flex-direction: column;
}

.seat-row {
      display: flex;
      margin-bottom: 5px;
}

.seat {
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      font-size: 12px;
      color: white;
      border: 1px solid #ccc;
      border-radius: 3px;
}

.seat.booked {
      background-color: #d9534f;
}

.seat.available {
      background-color: #5bc0de;
}

.seat.aisle {
      margin-right: 15px;
}

.seat-row {
      display: flex;
      align-items: center;
}
