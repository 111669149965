canvas {
      height: 70px !important;
      width: 214px !important;
}
#pdf-content {
      max-width: 1200px;
}

.main-title {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 20px;
      margin-bottom: 20px;
}

.main-card {
      padding: 15px 20px;
      border: 2px solid rgb(204, 194, 194);
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
            rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
            rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
            rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.card-title {
      font-weight: bold;
      font-size: 16px;
}
.card-text {
      font-size: 14px;
      line-height: 1.8;
}
.card-body ul {
      list-style-type: disc;
}

.card-body-first {
      border: none;
}

.card-body-first {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      /* padding: 10px; */
      border: none;
}

.company-info {
      width: 288px;
}

.pnr-section {
      text-align: center;
}

.airline-name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 4px;
}

.airline-logo {
      max-width: 40px;
      height: auto;
      margin: 0 auto 4px;
}

.pnr-info {
      margin-top: 5px;
}

.pnr {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 20px;
      margin: 0;
}

.pnr-label {
      font-size: 14px;
      color: #333;
      font-weight: 500;
}

.reference-info {
      text-align: right;
      width: 288px;
}

.reference-info p {
      margin: 0;
}

.qr-code {
      width: 200px;
      height: 100px;
      object-fit: cover;
      margin-top: 0px;
}

/* second table */
.card-title-h {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      align-items: center;
      background-color: #f1f1f1;
}

.card-subtitle {
      font-size: 14px;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-weight: bold;
}

.flight-details-table {
      width: 100%;
      border-collapse: collapse;
}

.flight-details-table th,
.flight-details-table td {
      border: 1px solid #ddd;
      border-left: none;
      border-bottom: none;
      padding: 5px;
      text-align: start;
      vertical-align: top;
}

.flight-details-table th {
      color: #747474;
      font-weight: bold;
      font-size: 14px;
}

.flight-info {
      display: flex;
}
.logo {
      width: 60px;
      height: 40px;
      margin-bottom: 10px;
}

.details {
      font-size: 14px;
      line-height: 1.6;
      font-weight: 500;
}

.class,
.aircraft-type,
.refundable {
      display: block;
      color: #333;
}

/* third table */
.card-third {
      padding-top: 20px;
      border: none;
}

.third-title {
      font-size: 16px;
}
.table-header {
      border-top: 2.5px solid black;
      border-bottom: 2.5px solid black;
}
.header-third {
      color: #747474;
      font-weight: bold;
      font-size: 14px;
}

.third-content {
      display: flex;
      justify-content: start;
}
.xyz {
      display: flex;
      justify-content: center;
}
.barcode-img {
      width: 150px;
      height: 60px;
      object-fit: cover;
      display: block;
}

/* fourth */
.payment-details {
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      padding-left: 5px;
      padding-right: 5px;
}

.card-title {
      margin-bottom: 0px;
      font-weight: bold;
}

.payment-row {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #dcdcdc;
      padding-top: 2px;
      padding-bottom: 2px;
}

.payment-row:first-child {
      border-top: none;
}

.gross-fare {
      background-color: #f5f5f5;
      font-weight: bold;
}

.payment-row .price {
      color: #333;
}

.btn-print {
      border: 1px solid black;
      padding: 5px 20px;
      border-radius: 6px;
}
.btn-print:hover {
      background-color: #333;
      color: white;
}
