.fancybox__container {
      z-index: 9999999 !important;
}

.pin svg {
      font-size: 24px;
}

.pin-text {
      background-color: white;
      min-width: 360px;
      border-radius: 5px;
      padding: 10px;
      color: hsla(0, 0%, 0%, 0.6);
      font-size: 16px;
      margin-top: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.pin-text h2 {
      font-size: 20px;
      padding-bottom: 5px;
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.6);
}

.pin-text p {
      font-size: 16px;
}

.rdp {
      display: flex;
      align-items: center;
      justify-content: center;
}

.rdp-months {
      /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
      /* border-radius: 5px; */
}

.rdp-caption {
      justify-content: center !important;
}

.rdp-nav {
      /* display: none; */
}

.rdp-month {
      border-radius: 5px;
      padding: 10px;
      background-color: white;
}

.rdp-day_selected,
.rdp-day_selected:focus-visible,
.rdp-day_selected:hover,
.rdp-selected .rdp-day_button,
.rdp-day_button:hover,
.rdp-range_start .rdp-day_button,
.rdp-range_end .rdp-day_button {
      background-color: #1e3b78 !important;
      color: #fff;
      opacity: 1;
      border-color: #1e3b78;
}

.rdp-chevron {
      fill: #1e3b78;
}

.spinner-border {
      height: 1.5rem !important;
      width: 1.5rem !important;
      border-width: 2px !important;
}

.offscreen {
      position: absolute;
      left: -9999px;
}

.pdf-content {
      page-break-inside: avoid;
      page-break-after: auto;
}
.qr-image {
      page-break-before: avoid;
}
